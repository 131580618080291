/*
 * @file: user.js
 * @description: Reducers and actions for store/manipulate Rule based Data
 * @date: 28.11.2019
 * @author: Anil
 */

const initialState = {
    cancellation_policy_count:0
,discontinued_products_count:0
,retrievable_order_count:0,
retrievable_product_count:0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'RULE_BASE':
            return { ...state,...action.data};
        default:
            return { ...state, ...action.data||initialState };
            
    }
}
