import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { Disclosure, Transition } from "@headlessui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { TbChartBar, TbFileReport } from "react-icons/tb";
import { PiHandCoins, PiHandbagBold } from "react-icons/pi";
import {
  FiCloud,
  FiCloudOff,
  FiPackage,
  FiSettings,
  FiThumbsUp,
  FiUsers,
} from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { HiOutlineInbox } from "react-icons/hi2";
import { RiCoupon2Fill } from "react-icons/ri";
import { LuFolderSymlink } from "react-icons/lu";
import { FaRegStar,FaStar } from "react-icons/fa";
import styles from "./index.module.css";
import {
  IoFileTrayFullOutline,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import environment from "../../../environment";
import { useEffect, useState } from "react";
import ApiClient from "../../../methods/api/apiClient";
import { login_success } from "../../../actions/user";
import ChatbotIcon from "../../../assets/chatbot-icon";
import { CostingConfig, CostingURL } from "../../../pages/CostingTemplate/Api";
import { GmailTempURL } from "../../../pages/GmailTemplate/Api";
import { CostingTourURL } from "../../../pages/CostPerTourTemplate/Api";
import { BannerURL } from "../../../pages/Banner/Api";
import { rule_base } from "../../../actions/rules";
import { sidebar_base } from "../../../actions/sidebar";

const Html = ({
  ListItemLink,
  tabclass,
  urlAllow,
  route,
  isOpen,
  scrollToId,
  CollapsedState,
  setCollapsedState,
}) => {
  const [SidebarLoad, setSidebarLoad] = useState(false);
  const CurrentServer = window.location.href?.includes("app.dazhboards.com")
    ? false
    : true;
  const location = useLocation();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const ValidationNumber =
    user?.supplier_id?.id ||
    user?.subRole?.id == environment.SubRolePartner ||
    user?.subRole?._id == environment.SubRolePartner? 0: 0;
  const navigate = useNavigate();
  const dataConnectionLink = [
    "/pos",
    "/api/review-connected",
    "/api/review",
    "/marketing-analytics",
    "/api/accounting",
    "/marketing-software",
  ];
  const dataConnection = dataConnectionLink.includes(location.pathname);
  useEffect(() => {
    if(localStorage.getItem('token')&&user?._id){
    ApiClient.get(`api/user/profile`, { id: user?.id || user?._id }, "", "", {
      apiCall: true,
    }).then((res) => {
      if (res.success) {
        setData(res.data);
        const data = res?.data;
        if (data?.subRole && data?.subRole?._id) {
          data["subRole"] = { ...data?.subRole, id: data?.subRole?._id };
        }
        dispatch(login_success(data));
      }
    });
  }
  }, []);
 
const [RuleData,setRuleData]=useState({})
  const RuleBasedData=()=>{
    if(localStorage.getItem('token')){
    ApiClient.get(`api/users/sidebar/data-count`,{},'','',{apiCall:true}).then(res=>{
      if(res.success){
        setRuleData(res.data)
        // dispatch(rule_base(res.data))
      }
    })
  }
  }

 

  useEffect(() => {
    RuleBasedData();
  }, []);


  const FavUnFavReturn=(name='')=>{
    if(true){
      return null
    }
    if(user?.pinnedTabs?.includes(name)){
      return <span onClick={e=>FavUnFavUpdater(e,true,name)}> <FaStar className="text-primary" /></span>
    }else{
      return <span onClick={e=>FavUnFavUpdater(e,false,name)}> <FaRegStar className="text-primary"  /></span>
    }

  }
  const FavUnFavUpdater=(e,type,name)=>{
    e.preventDefault();
    if(!CurrentServer){
      return null
    }
    let data5=user?.pinnedTabs||[];
    if(type){
data5=user?.pinnedTabs?.filter(item=>item!=name)
    }else{
data5.push(name)
    }
 
    dispatch(login_success({...user,pinnedTabs:data5}))
    ApiClient.put(`api/user/profile`,{id:user?._id,pinnedTabs:data5});

  }
  return (
    <>
      <div className={`px-[8px] ${isOpen && styles.sm_sidebar}`}>
        <h6
          className={`${
            isOpen ? "py-[12px] text-center" : "py-[12px]"
          } text-xs font-medium text-[#7E8B99] mt-[12px]`}>
          <p className="flex items-center justify-between">
            <span className="sidebar_text"> MAIN MENU </span>
            <p
              className=" flex items-center gap-2"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setCollapsedState(!CollapsedState);
                dispatch(
                  sidebar_base({
                    value: !CollapsedState,
                    allState: !CollapsedState,
                  })
                );
                setSidebarLoad(true);
                setTimeout(() => {
                  setSidebarLoad(false);
                }, 100);
              }}>
              {!CollapsedState ? "Expand All" : "Collapse All"}
              <TiArrowSortedDown
                className={`${
                  !CollapsedState
                    ? "-rotate-90 transform"
                    : "rotate-0 transform"
                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
              />
            </p>
          </p>
        </h6>
        {user?.subscriptionId ||
        user?.on_trial ||
        user?.supplier_id?.id ||
        user?.subRole?.id == environment.SubRolePartner ||
        user?.subRole?._id == environment.SubRolePartner ? (
          <>
            {SidebarLoad ? null : (
              <ul className="space-y-2">
                {urlAllow("readDashboard") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    <NavLink
                      to="/dashboard" 
                      id="1"
                      className={({ isActive }) =>
                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#00b884] hover:bg-[#00b884]/10 !no-underline transition-all " +
                        (isActive &&
                          " !text-[#00b884] !bg-[#EDECF9] !font-medium")
                      }>
                      <RiHome6Line className="text-[#00b884] shrink-0 text-lg" />
                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                        Dashboard {FavUnFavReturn('Dashboard')}
                      </span>
                    </NavLink>
                  </li>
                )}

                {urlAllow("readOmniChannel") ? (
                  <>
                    <li
                      className={
                        (user?.booking_system_count &&
                          user?.booking_system_count) ||
                        0 >= ValidationNumber
                          ? ""
                          : "pointer-events-none"
                      }>
                      {" "}
                      <Disclosure as="div" defaultOpen={tabclass("customRelation")}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-primary hover:bg-primary/10 transition-all">
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                <IoChatboxEllipsesOutline className="text-primary shrink-0 text-lg" />
                                <span className="sidebar_text">
                                  {!user.sidebar ? (
                                    "Customer Relations"
                                  ) : (
                                    <>
                                      Comm
                                      <br />
                                      Channel
                                    </>
                                  )}
                                </span>
                              </span>
                              <TiArrowSortedDown
                                className={`${
                                  open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                            <Transition
                              enter="transition duration-300 ease-in-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-300 opacity-300"
                              leave="transition duration-300 ease-in-out"
                              leaveFrom="transform scale-300 opacity-300"
                              leaveTo="transform scale-95 opacity-0">
                              <Disclosure.Panel
                                className={`${
                                  !isOpen && "pl-[25px]"
                                } mt-[4px] `}>
                                <ul className="space-y-2">

                                    <Disclosure
                                    as="div"
                                    defaultOpen={tabclass("omni")}>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          onClick={(e) => {
                                            setCollapsedState(true);
                                            dispatch(
                                              sidebar_base({ value: true })
                                            );
                                          }}
                                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-primary hover:bg-primary/10 transition-all">
                                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                            <span className="sidebar_text">
                                              Omni Channel
                                            </span>
                                          </span>
                                          <TiArrowSortedDown
                                            className={`${
                                              open ? "" : "-rotate-90 transform"
                                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                          />
                                        </Disclosure.Button>
                                        <Transition
                                          enter="transition duration-300 ease-in-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-300 opacity-300"
                                          leave="transition duration-300 ease-in-out"
                                          leaveFrom="transform scale-300 opacity-300"
                                          leaveTo="transform scale-95 opacity-0">
                                          <Disclosure.Panel
                                            className={`${
                                              !isOpen && "pl-[25px]"
                                            } mt-[4px] `}>
                                            <ul className="space-y-2">
                                  <li>
                                    <NavLink
                                      to="/chatbot"
                                      id="2"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Chatbot{FavUnFavReturn('Chatbot')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/omni-channels/live-chat">
                                    <NavLink
                                      to="/omni-channels/live-chat"
                                      id="3"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Chat {FavUnFavReturn('Chat')}
                                      </span>
                                    </NavLink>
                                  </li>

                                  <Disclosure
                                    as="div"
                                    defaultOpen={tabclass("templates")}>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          onClick={(e) => {
                                            setCollapsedState(true);
                                            dispatch(
                                              sidebar_base({ value: true })
                                            );
                                          }}
                                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-primary hover:bg-primary/10 transition-all">
                                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                            <span className="sidebar_text">
                                              Templates
                                            </span>
                                          </span>
                                          <TiArrowSortedDown
                                            className={`${
                                              open ? "" : "-rotate-90 transform"
                                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                          />
                                        </Disclosure.Button>
                                        <Transition
                                          enter="transition duration-300 ease-in-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-300 opacity-300"
                                          leave="transition duration-300 ease-in-out"
                                          leaveFrom="transform scale-300 opacity-300"
                                          leaveTo="transform scale-95 opacity-0">
                                          <Disclosure.Panel
                                            className={`${
                                              !isOpen && "pl-[25px]"
                                            } mt-[4px] `}>
                                            <ul className="space-y-2">
                                              <li id="/whatsapp/template">
                                                <NavLink
                                                  to="/whatsapp/template"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                                    (isActive &&
                                                      " !text-primary !bg-[#705EE6]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    WhatsApp {FavUnFavReturn('WhatsApp Template')}
                                                  </span>
                                                </NavLink>
                                              </li>

                                              <li id={GmailTempURL.list}>
                                                <NavLink
                                                  to={GmailTempURL.list}
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                                    ((isActive ||
                                                      location.pathname.includes(
                                                        "gmail"
                                                      )) &&
                                                      !location.pathname.includes(
                                                        "@gmail"
                                                      ) &&
                                                      " !text-primary !bg-[#705EE6]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Email{FavUnFavReturn('Email Template')}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            </ul>
                                          </Disclosure.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Disclosure>

                                  {urlAllow("readOmniChannel") ? (
                                    <>
                                      <li id="/omni-channel-setting/widget">
                                        <NavLink
                                          to="/omni-channel-setting/widget"
                                          className={({ isActive }) =>
                                            "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                            (isActive &&
                                              " !text-primary !bg-[#705EE6]/10 !font-medium")
                                          }>
                                          {isOpen && (
                                            <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                          )}
                                          <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                            Widget Settings{FavUnFavReturn('Chat Widget')}
                                          </span>
                                        </NavLink>
                                      </li>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  </ul>
                                  </Disclosure.Panel>
                                  </Transition>
                                  </>)}
                                  </Disclosure>

                                      {urlAllow("readReviews") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("reviews")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 transition-all"
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                              // setTimeout(() => {
                              //   scrollToId("/reviews");
                              // }, 100);
                              var element = document.getElementById('Godiv');
        element.scrollBy({
            top: -50, // Scroll up by 50 pixels
            behavior: 'smooth' // Optional: Smooth scroll
        });
                            }}>
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]" id="Godiv">
                              {/* <FiThumbsUp className="text-[#705EE6] shrink-0 text-lg" /> */}
                              <span className="sidebar_text">Reviews</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                <li id="/review-dashboard">
                                  <NavLink
                                    to="/review-dashboard"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiThumbsUp className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Review Insights {FavUnFavReturn('Review Insights')}
                                    </span>
                                  </NavLink>
                                </li>
                                <li id="/reviews">
                                  <NavLink
                                    to="/reviews"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                      (location.pathname == "/reviews" &&
                                        " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiThumbsUp className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Review Management {FavUnFavReturn('Review Management')}
                                    </span>
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink
                                    to="/reviews/settings"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                      (location.pathname ==
                                        "/reviews/settings" &&
                                        " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiThumbsUp className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Reviews Settings {FavUnFavReturn('Review Settings')}
                                    </span>
                                  </NavLink>
                                </li>
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                        {urlAllow("readWaivers") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("waivers")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              {/* <FiUsers className="text-[#705EE6] shrink-0 text-lg" /> */}
                              <span className="sidebar_text">Waivers</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                {urlAllow("readWaivers") && (
                                  <li>
                                    <NavLink
                                      to="/waiver/list"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        ((isActive ||
                                          location.pathname.includes(
                                            "waiver"
                                          )) &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Waiver Templates {FavUnFavReturn('Waiver Templates')}
                                      </span>
                                    </NavLink>
                                  </li>
                                )}
 
                         
                                {urlAllow("readWaivers") && (
                                  <li>
                                    <NavLink
                                      to="/wavier/settings"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Waiver Settings {FavUnFavReturn('Waiver Settings')}
                                      </span>
                                    </NavLink>
                                  </li>
                                )}
                                {urlAllow("readWaivers") && (
                                  <li>
                                    <NavLink
                                      to="/waidatabase"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Waiver Database {FavUnFavReturn('Waiver Database')}
                                      </span>
                                    </NavLink>
                                  </li>
                                )}
                                        
                                 
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>

                   
                  </li>
                )}
                                </ul>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {urlAllow("readSales") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("sales")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between text-[#4A545E]  hover:!text-[#1b88c0] gap-[12px] hover:bg-[#1b88c0]/10 transition-all duration-300">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px] crm">
                              <TbChartBar className="text-[#1b88c0] shrink-0 text-lg" />
                              <span className="sidebar_text">Sales</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } h-4 w-4 transition-all duration-500  text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                <li>
                                  <NavLink
                                    to="/sales"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                      (location?.pathname == "/sales" &&
                                        " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <TbChartBar className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Sales Insights {FavUnFavReturn('Sales Insight')}
                                    </span>
                                  </NavLink>
                                </li>
                                      {urlAllow("readCustomers") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    {/* <Disclosure as="div" defaultOpen={tabclass("customers")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#705EE6] hover:bg-[#705EE6]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <FiUsers className="text-[#705EE6] shrink-0 text-lg" />
                              <span className="sidebar_text">Customers</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}> */}
                              {/* <ul className="space-y-2"> */}
                                <li>
                                  <NavLink
                                    to="/customers"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiUsers className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Customer Insights {FavUnFavReturn('Customer Insights')}
                                    </span>
                                  </NavLink>
                                </li>
                                {/* <li>
                                  <NavLink
                                    to="/customer-data"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#705EE6]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        location.pathname.includes(
                                          `customerdetails`
                                        )) &&
                                        " !text-[#705EE6] !bg-[#705EE6]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiUsers className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Customer Data {FavUnFavReturn('Customer Data')}
                                    </span>
                                  </NavLink>
                                </li> */}
                              {/* </ul> */}
                            {/* </Disclosure.Panel> */}
                          {/* </Transition> */}
                        {/* </> */}
                      {/* )} */}
                    {/* </Disclosure> */}
                  </li>
                )}
                                <li>
                                  <NavLink
                                    to="/sales-data"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                      ((location?.pathname == "/sales-data" ||
                                        location.pathname.includes("orders")) &&
                                        " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <TbChartBar className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Sales Data {FavUnFavReturn('Sales Data')}
                                    </span>
                                  </NavLink>
                                </li>
                                {RuleData?.retrievable_order_count ==
                                0 ? null : (
                                  <li>
                                    <NavLink
                                      to="/sales/retrievable"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                        (location?.pathname ==
                                          "/sales/retrievable" &&
                                          " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <TbChartBar className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Historical Data {FavUnFavReturn('Historial Sales')}
                                      </span>
                                    </NavLink>
                                  </li>
                                )}
                                <li>
                                  <NavLink
                                    to="/sales/refunds"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                      (location?.pathname == "/sales/refunds" &&
                                        " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <TbChartBar className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                       Refund Data {FavUnFavReturn('Sales Refund Data')}
                                    </span>
                                  </NavLink>
                                </li>

                                  {urlAllow("readDynamicPricing") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
               
                                <li>
                                  <NavLink
                                    to="/dynamicprice-list"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#1b88c0] hover:bg-[#1b88c0]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        location.pathname.includes(
                                          "dynamicprice"
                                        )) &&
                                        " !text-[#1b88c0] !bg-[#1b88c0]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <PiHandCoins className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Price Management {FavUnFavReturn('Manage Rules')}
                                    </span>
                                  </NavLink>
                                </li>
                        
                  </li>
                )}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                {urlAllow("readProducts") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure
                      as="div"
                      defaultOpen={tabclass("productdashboard")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#4529ff] hover:bg-[#4529ff]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <FiPackage className="text-[#4529ff] shrink-0 text-lg" />
                              <span className="sidebar_text">Products</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                <li>
                                  <NavLink
                                    to="/product-dashboard"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiPackage className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Product Insights {FavUnFavReturn('Product Insights')}
                                    </span>
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink
                                    to="/product-data"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        location.pathname.includes(
                                          `/product/`
                                        )) &&
                                        !location.pathname.includes(
                                          "/delete/product"
                                        ) &&
                                        " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiPackage className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Product Data{FavUnFavReturn('Product Data')}
                                    </span>
                                  </NavLink>
                                </li>
                                {RuleData?.discontinued_products_count ==
                                0 ? null : (
                                  <li>
                                    <NavLink
                                      to="/delete/product-data/"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                        ((isActive ||
                                          window.location.pathname.includes(
                                            "/delete/product/"
                                          )) &&
                                          " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <FiPackage className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Deleted Products  {FavUnFavReturn('Deleted Products')}
                                      </span>
                                    </NavLink>
                                  </li>
                                )}

                                {/*  FOr Product Retrivable DATA */}
                                {RuleData?.retrievable_product_count ==
                                0 ? null : (
                                  <li>
                                    <NavLink
                                      to="/products/retrivable"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <FiPackage className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Historical Data {FavUnFavReturn('Historical Product')}
                                      </span>
                                    </NavLink>
                                  </li>
                                )}

                                <li>
                                  <NavLink
                                    to="/cancel/policy"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Cancellation Policies {FavUnFavReturn('Cancellation Policies')}
                                    </span>
                                  </NavLink>
                                </li>
                     {CurrentServer&&<li>
                                  <Disclosure as="div" defaultOpen={"contents"}>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          onClick={(e) => {
                                            setCollapsedState(true);
                                            dispatch(
                                              sidebar_base({ value: true })
                                            );
                                          }}
                                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#4529ff] hover:bg-[#4529ff]/10 transition-all">
                                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                            {/* <FiCloud className="text-[#fd71af] shrink-0 text-lg" /> */}
                                            <span className="sidebar_text">
                                              Content Updates
                                            </span>
                                          </span>
                                          <TiArrowSortedDown
                                            className={`${
                                              open ? "" : "-rotate-90 transform"
                                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                          />
                                        </Disclosure.Button>
                                        <Transition
                                          enter="transition duration-300 ease-in-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-300 opacity-300"
                                          leave="transition duration-300 ease-in-out"
                                          leaveFrom="transform scale-300 opacity-300"
                                          leaveTo="transform scale-95 opacity-0">
                                          <Disclosure.Panel
                                            className={`${
                                              !isOpen && "pl-[25px]"
                                            } mt-[4px] `}>
                                            <ul className="space-y-2">
                                              <li>
                                                <NavLink
                                                  to="/reseller/updates"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                    ((isActive ||
                                                      window.location.pathname.includes(
                                                        "/content/detail"
                                                      )) &&
                                                      " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <FiUsers className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Update Logs {FavUnFavReturn('Update Logs')}
                                                  </span>
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink
                                                  to="/content/settings"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                    (isActive &&
                                                      " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <FiUsers className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Settings {FavUnFavReturn('Update Logs Setting')}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            </ul>
                                          </Disclosure.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Disclosure>
                                </li>}
                                <li>
                                  <Disclosure
                                    as="div"
                                    defaultOpen={"costingTemp"}>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          onClick={(e) => {
                                            setCollapsedState(true);
                                            dispatch(
                                              sidebar_base({ value: true })
                                            );
                                          }}
                                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#4529ff] hover:bg-[#4529ff]/10 transition-all">
                                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                            {/* <FiCloud className="text-[#705EE6] shrink-0 text-lg" /> */}
                                            <span className="sidebar_text">
                                              Costing Templates
                                            </span>
                                          </span>
                                          <TiArrowSortedDown
                                            className={`${
                                              open ? "" : "-rotate-90 transform"
                                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                          />
                                        </Disclosure.Button>
                                        <Transition
                                          enter="transition duration-300 ease-in-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-300 opacity-300"
                                          leave="transition duration-300 ease-in-out"
                                          leaveFrom="transform scale-300 opacity-300"
                                          leaveTo="transform scale-95 opacity-0">
                                          <Disclosure.Panel
                                            className={`${
                                              !isOpen && "pl-[25px]"
                                            } mt-[4px] `}>
                                            <ul className="space-y-2">
                                              {urlAllow("readCosting") && (
                                                <li
                                                  className={
                                                    (user?.booking_system_count &&
                                                      user?.booking_system_count) ||
                                                    0 >= ValidationNumber
                                                      ? ""
                                                      : "pointer-events-none"
                                                  }>
                                                  <NavLink
                                                    to={CostingURL.list}
                                                    className={({ isActive }) =>
                                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                      (location.pathname.includes(
                                                        `/costing/`
                                                      ) ||
                                                      location.pathname ==
                                                        `/costing/template`
                                                        ? " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium"
                                                        : "")
                                                    }>
                                                    {isOpen && (
                                                      <FiPackage className="text-inherit shrink-0 text-lg" />
                                                    )}
                                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                      Budget Per Tour {FavUnFavReturn('Budget Per Tour')}
                                                    </span>
                                                  </NavLink>
                                                </li>
                                              )}

                                              <li>
                                                <NavLink
                                                  to={CostingTourURL.list}
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4529ff] hover:bg-[#4529ff]/10 !no-underline transition-all " +
                                                    ((isActive ||
                                                      location.pathname.includes(
                                                        "costingtour"
                                                      )) &&
                                                      " !text-[#4529ff] !bg-[#4529ff]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Costing Per Tour{FavUnFavReturn('Costing Per Tour')}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            </ul>
                                          </Disclosure.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Disclosure>
                                </li>

                                {/* Sub section of product, Content Updates Settings  */}

           
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

             

              

          

            

                {urlAllow("readReseller") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("crm")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 transition-all"
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                              // setTimeout(() => {
                              //   scrollToId("/reseller-data");
                              // }, 100);
                            }}>
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px] crm">
                              <HiOutlineInbox className="text-[#bb66e1] shrink-0 text-lg" />
                              <span className="sidebar_text">Reseller</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-500 ease-in-out delay-150"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-500 ease-in-out delay-150"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                <li id="/reseller-dashboard">
                                  <NavLink
                                    to="/reseller-dashboard"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Resellers Insights {FavUnFavReturn('Reseller Insights')}
                                    </span>
                                  </NavLink>
                                </li>
                                <li id="/reseller-data">
                                  <NavLink
                                    to="/reseller-data"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      List of Resellers {FavUnFavReturn('List of Resellers')}
                                    </span>
                                  </NavLink>
                                </li>
                                {/* <li id="/invoice-commission">
                                  <NavLink
                                    to="/invoice-commission"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                       Invoice Commission Settings 
                                    </span>
                                  </NavLink>
                                </li> */}

                                <Disclosure
                                  as="div"
                                  defaultOpen={tabclass("InvoicesRes")}>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        onClick={(e) => {
                                          setCollapsedState(true);
                                          dispatch(
                                            sidebar_base({ value: true })
                                          );
                                        }}
                                        className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 transition-all">
                                        <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                          {/* <HiOutlineSpeakerphone className="text-[#1f7046] shrink-0 text-lg" /> */}
                                          <span className="sidebar_text">
                                            Invoices
                                          </span>
                                        </span>
                                        <TiArrowSortedDown
                                          className={`${
                                            open ? "" : "-rotate-90 transform"
                                          } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                        />
                                      </Disclosure.Button>
                                      <Transition
                                        enter="transition duration-300 ease-in-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-300 opacity-300"
                                        leave="transition duration-300 ease-in-out"
                                        leaveFrom="transform scale-300 opacity-300"
                                        leaveTo="transform scale-95 opacity-0">
                                        <Disclosure.Panel
                                          className={`${
                                            !isOpen && "pl-[25px]"
                                          } mt-[4px] `}>
                                          <ul className="space-y-2">
                                          
                                              <li>
                                  <NavLink
                                    to="/reseller-invoices"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      ((isActive||location.pathname.includes('supplier-invoice-detail')) &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      List of Invoices {FavUnFavReturn('Reseller Invoices')}
                                    </span>
                                  </NavLink>
                                </li>
                            


{CurrentServer&&<li>
                                  <NavLink
                                    to="/invoice/template"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                      ((isActive||location?.pathname.includes('inovoice/template/')) &&
                                        " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                       Invoice Templates {FavUnFavReturn(' Invoice Templates')}
                                    </span>
                                  </NavLink>
                                </li>}
                                          </ul>
                                        </Disclosure.Panel>
                                      </Transition>
                                    </>
                                  )}
                                </Disclosure>
                           


                                <Disclosure
                                  as="div"
                                  defaultOpen={tabclass("templatescrm")}>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        onClick={(e) => {
                                          setCollapsedState(true);
                                          dispatch(
                                            sidebar_base({ value: true })
                                          );
                                        }}
                                        className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 transition-all">
                                        <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                          {/* <HiOutlineSpeakerphone className="text-[#1f7046] shrink-0 text-lg" /> */}
                                          <span className="sidebar_text">
                                            Contracts
                                          </span>
                                        </span>
                                        <TiArrowSortedDown
                                          className={`${
                                            open ? "" : "-rotate-90 transform"
                                          } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                        />
                                      </Disclosure.Button>
                                      <Transition
                                        enter="transition duration-300 ease-in-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-300 opacity-300"
                                        leave="transition duration-300 ease-in-out"
                                        leaveFrom="transform scale-300 opacity-300"
                                        leaveTo="transform scale-95 opacity-0">
                                        <Disclosure.Panel
                                          className={`${
                                            !isOpen && "pl-[25px]"
                                          } mt-[4px] `}>
                                          <ul className="space-y-2">
                                          
                                            {urlAllow("readContracts") && (
                                              <li id="/crm">
                                                <NavLink
                                                  to="/crm"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#bb66e1] hover:bg-[#bb66e1]/10 !no-underline transition-all " +
                                                    (isActive &&
                                                      " !text-[#bb66e1] !bg-[#bb66e1]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Contract Templates {FavUnFavReturn('Contract Templates')}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            )}
                                          </ul>
                                        </Disclosure.Panel>
                                      </Transition>
                                    </>
                                  )}
                                </Disclosure>
                            
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                {urlAllow("readFinancials") && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("financial")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 transition-all"
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                              setTimeout(() => {
                                scrollToId("/accounting/bills");
                              }, 100);
                            }}>
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <PiHandbagBold className="text-[#7b68ee] shrink-0 text-lg" />
                              <span className="sidebar_text">Profitability</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-500 ease-in-out delay-150"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-500 ease-in-out delay-150"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                <li id="/accounting/dashboard">
                                  <NavLink
                                    to="/accounting/dashboard"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                       Insights {FavUnFavReturn('Profitability Insights')}
                                    </span>
                                  </NavLink>
                                </li>
                                <li id="/accounting/transactions">
                                  <NavLink
                                    to="/accounting/transactions"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#7b68ee] !bg-[#7b68ee]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Profitability Data{FavUnFavReturn('Profitability Data')}
                                    </span>
                                  </NavLink>
                                </li>  
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                 {urlAllow("readMarketing")&&!CurrentServer && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("marketing")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" />
                              <span className="sidebar_text">Marketing</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                              
                                <li
                                  id="/marketing/google-analytics"
                                  className={`${
                                    CurrentServer ? "" : "pointer-events-none"
                                  } `}>
                                  <NavLink
                                    to="/marketing/google-analytics"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Google Analytics Summary
                                      {CurrentServer&&FavUnFavReturn('Google Marketing')}
                                      {CurrentServer ? null : (
                                        <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                          Coming Soon
                                        </span>
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                                  {CurrentServer && (
                  <li>
                    <Disclosure as="div" defaultOpen={tabclass("socialMedia")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              {/* <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" /> */}
                              <span className="sidebar_text">Social Media</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                <li id="/social/media">
                                  <NavLink
                                    to="/social/media"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Social Media Schedule {FavUnFavReturn('Social Media')}
                                    </span>
                                  </NavLink>
                                </li>
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}
                                <li
                                  className="pointer-events-none"
                                  id="/marketing/google-ads">
                                  <NavLink
                                    to="/marketing/google-ads"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Google Ads{" "}
                                      <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                        Coming Soon
                                      </span>
                                    </span>
                                  </NavLink>
                                </li>
                                <li
                                  className="pointer-events-none"
                                  id="/google/tags">
                                  <NavLink
                                    to="/marketing/facebook-ads"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Facebook Ads{" "}
                                      <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                        Coming Soon
                                      </span>
                                    </span>
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink
                                    to="/google/tags"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        window.location.pathname.includes(
                                          "google/tag"
                                        )) &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Manage Google Tags {CurrentServer&&FavUnFavReturn('Google Tag Manager')}
                                    </span>
                                  </NavLink>
                                </li>

                                {false && (
                                  <>
                                    <li>
                                      <NavLink
                                        to="/coupons"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#4A545E]/10 !font-medium")
                                        }>
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Insights
                                        </span>
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/coupons-data"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4A545E] hover:bg-[#ffc800] !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#ffc800]/10 !font-medium")
                                        }>
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Data
                                        </span>
                                      </NavLink>
                                    </li>
                                  </>
                                )}

                    {CurrentServer&&               <li
                      className={
                        (user?.booking_system_count &&
                          user?.booking_system_count) ||
                        0 >= ValidationNumber
                          ? ""
                          : "pointer-events-none"
                      }>
                      {" "}
                      <Disclosure as="div" defaultOpen={tabclass("affiliates")}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10   transition-all">
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                {/* <LuFolderSymlink className="text-primary shrink-0 text-lg" /> */}
                                <span className="sidebar_text">
                                  {!user.sidebar ? (
                                    "Affiliates"
                                  ) : (
                                    <>
                                      Comm
                                      <br />
                                      Channel
                                    </>
                                  )}
                                </span>
                              </span>

                              <TiArrowSortedDown
                                className={`${
                                  open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                            <Transition
                              enter="transition duration-300 ease-in-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-300 opacity-300"
                              leave="transition duration-300 ease-in-out"
                              leaveFrom="transform scale-300 opacity-300"
                              leaveTo="transform scale-95 opacity-0">
                              <Disclosure.Panel
                                className={`${
                                  !isOpen && "pl-[25px]"
                                } mt-[4px] `}>
                                <ul className="space-y-2">
                                  <li id="/affiliate">
                                    <NavLink
                                      to="/affiliate"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }>
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Affiliate DataBase {FavUnFavReturn('Affiliate Database')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/current-affiliate">
                                    <NavLink
                                      to="/current-affiliate"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Current Affiliate {FavUnFavReturn('Current Affiliate')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/request">
                                    <NavLink
                                      to="/request"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Affiliate Request {FavUnFavReturn('Affiliate Request')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id={BannerURL.general}>
                                    <NavLink
                                      to={BannerURL.general}
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        General Banners {FavUnFavReturn('General Banners')}
                                      </span>
                                    </NavLink>
                                  </li>

                                  <li id={BannerURL.product}>
                                    <NavLink
                                      to={BannerURL.product}
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Products Banners {FavUnFavReturn('Products Banners')}
                                      </span>
                                    </NavLink>
                                  </li>

                                  {urlAllow("default-commission") && (
                                    <li
                                      className={
                                        (user?.booking_system_count &&
                                          user?.booking_system_count) ||
                                        0 >= ValidationNumber
                                          ? ""
                                          : "pointer-events-none"
                                      }
                                      id={"/default-commission"}>
                                      <NavLink
                                        to={"/default-commission"}
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                          (location.pathname.includes(
                                            `/costing`
                                          ) ||
                                          location.pathname ==
                                            `/default-commission`
                                            ? " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium"
                                            : "")
                                        }>
                                        {isOpen && (
                                          <FiPackage className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Default Commission {FavUnFavReturn('Default Commission')}
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                  <li id="/affiliateInbox">
                                    <NavLink
                                      to="/affiliateInbox"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Inbox {FavUnFavReturn('Affiliate Requese')}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </li>}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}
                
                <li>
                  <Disclosure as="div" defaultOpen={tabclass("settings")}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ff9900] hover:bg-[#ff9900]/10 transition-all"
                          onClick={(e) => {
                            setCollapsedState(true);
                            dispatch(sidebar_base({ value: true }));
                            setTimeout(() => {
                              scrollToId("/users");
                            }, 100);
                          }}>
                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                            <FiSettings className="text-[#ff9900] shrink-0 text-lg" />
                            <span className="sidebar_text">Settings</span>
                          </span>
                          <TiArrowSortedDown
                            className={`${
                              open ? "" : "-rotate-90 transform"
                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                          />
                        </Disclosure.Button>
                        <Transition
                          enter="transition duration-500 ease-in-out delay-150"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-300 opacity-300"
                          leave="transition duration-500 ease-in-out delay-150"
                          leaveFrom="transform scale-300 opacity-300"
                          leaveTo="transform scale-95 opacity-0">
                          <Disclosure.Panel
                            className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                            <ul className="space-y-2">
                              {urlAllow("readCompany") && (
                                <li id="/company">
                                  <NavLink
                                    to="/company"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiSettings className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Company Details {FavUnFavReturn('Company Details')}
                                    </span>
                                  </NavLink>
                                </li>
                              )}

                              {(user?.subRole &&
                                user?.subRole?.id ==
                                  environment.SubRolePartner) ||
                              !user?.partner_status
                                ? null
                                : urlAllow("editPartner") && (
                                    <li id="/partner">
                                      <NavLink
                                        to="/partner"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                        }>
                                        {isOpen && (
                                          <FiSettings className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Partners {FavUnFavReturn('Partners')}
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                              {urlAllow("readEmailSetting") && (
                                <li
                                  className={
                                    (user?.booking_system_count &&
                                      user?.booking_system_count) ||
                                    0 >= ValidationNumber
                                      ? ""
                                      : "pointer-events-none"
                                  }>
                                  <Disclosure
                                    as="div"
                                    defaultOpen={tabclass("emailSetting")}>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          onClick={(e) => {
                                            setCollapsedState(true);
                                            dispatch(
                                              sidebar_base({ value: true })
                                            );
                                          }}
                                          className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ff9900] hover:bg-[#ff9900]/10 transition-all">
                                          <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                            {/* <HiOutlineSpeakerphone className="text-[#1f7046] shrink-0 text-lg" /> */}
                                            <span className="sidebar_text">
                                              Email Settings
                                            </span>
                                          </span>
                                          <TiArrowSortedDown
                                            className={`${
                                              open ? "" : "-rotate-90 transform"
                                            } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                                          />
                                        </Disclosure.Button>
                                        <Transition
                                          enter="transition duration-300 ease-in-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-300 opacity-300"
                                          leave="transition duration-300 ease-in-out"
                                          leaveFrom="transform scale-300 opacity-300"
                                          leaveTo="transform scale-95 opacity-0">
                                          <Disclosure.Panel
                                            className={`${
                                              !isOpen && "pl-[25px]"
                                            } mt-[4px] `}>
                                            <ul className="space-y-2">
                                              <li id="/email/list">
                                                <NavLink
                                                  to="/email/list"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                                    (isActive &&
                                                      " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Email List {FavUnFavReturn('Email List')}
                                                  </span>
                                                </NavLink>
                                              </li>

                                              <li id="/email/templates">
                                                <NavLink
                                                  to="/email/templates"
                                                  className={({ isActive }) =>
                                                    "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                                    ((isActive ||
                                                      location.pathname.includes(
                                                        `email/addedit`
                                                      )) &&
                                                      " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                                  }>
                                                  {isOpen && (
                                                    <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                                  )}
                                                  <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                                    Email Templates {FavUnFavReturn('Email Templates')}
                                                  </span>
                                                </NavLink>
                                              </li>
                                            </ul>
                                          </Disclosure.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Disclosure>
                                </li>
                              )} 
                              {urlAllow("readDataConnection") ? (
                                <li id="/pos">
                                  <NavLink
                                    to="/pos"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      ((dataConnection ||
                                        location.pathname.includes("pos") ||
                                        location.pathname?.includes(
                                          "/api/review"
                                        ) ||
                                        location?.pathname ==
                                          "/omni-channel-setting/copy") &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Data connection {FavUnFavReturn('Data Connection')}
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}
                              {urlAllow("readUsers") && (
                                <li
                                  className={
                                    (user?.booking_system_count &&
                                      user?.booking_system_count) ||
                                    0 >= ValidationNumber
                                      ? ""
                                      : "pointer-events-none"
                                  }
                                  id="/users">
                                  <NavLink
                                    to="/users"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Users {FavUnFavReturn('Users')}
                                    </span>
                                  </NavLink>
                                </li>
                              )}
                              {urlAllow("readPlans") && (
                                <li id="/activeplan">
                                  <NavLink
                                    to={"/activeplan"}
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiSettings className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Plan
                                      {FavUnFavReturn('Plans')}
                                    </span>
                                  </NavLink>
                                </li>
                              )}
                              {urlAllow("readCards") && (
                                <li id="/card">
                                  <NavLink
                                    to="/card"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <FiSettings className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Billing {FavUnFavReturn('Billings')}
                                    </span>
                                  </NavLink>
                                </li>
                              )}
                            </ul>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </li>

                <li
                  className={
                    (user?.booking_system_count &&
                      user?.booking_system_count) ||
                    0 >= ValidationNumber
                      ? ""
                      : "pointer-events-none"
                  }>
                 
                </li>
              </ul>
            )}
            {SidebarLoad||!CurrentServer ? null : (
              <h6
                className={`${
                  isOpen ? "py-[12px] text-center" : "p-[12px]"
                } text-xs font-medium text-[#7E8B99] mt-[12px]`}>
                <span className="sidebar_text"> Add-ons </span>
              </h6>
            )}
            {SidebarLoad ? null : (
              <ul className="space-y-2">
        
     {urlAllow("readMarketing")&&CurrentServer && (
                  <li
                    className={
                      (user?.booking_system_count &&
                        user?.booking_system_count) ||
                      0 >= ValidationNumber
                        ? ""
                        : "pointer-events-none"
                    }>
                    {" "}
                    <Disclosure as="div" defaultOpen={tabclass("marketing")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" />
                              <span className="sidebar_text">Marketing</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                              
                                <li
                                  id="/marketing/google-analytics"
                                  className={`${
                                    CurrentServer ? "" : "pointer-events-none"
                                  } `}>
                                  <NavLink
                                    to="/marketing/google-analytics"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Google Analytics Summary
                                      {CurrentServer&&FavUnFavReturn('Google Marketing')}
                                      {CurrentServer ? null : (
                                        <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                          Coming Soon
                                        </span>
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                                  {CurrentServer && (
                  <li>
                    <Disclosure as="div" defaultOpen={tabclass("socialMedia")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              {/* <HiOutlineSpeakerphone className="text-[#ffc800] shrink-0 text-lg" /> */}
                              <span className="sidebar_text">Social Media</span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2">
                                <li id="/social/media">
                                  <NavLink
                                    to="/social/media"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineInbox className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Social Media Schedule {FavUnFavReturn('Social Media')}
                                    </span>
                                  </NavLink>
                                </li>
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}
                                <li
                                  className="pointer-events-none"
                                  id="/marketing/google-ads">
                                  <NavLink
                                    to="/marketing/google-ads"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Google Ads{" "}
                                      <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]">
                                        Coming Soon
                                      </span>
                                    </span>
                                  </NavLink>
                                </li>
                                <li
                                  className="pointer-events-none"
                                  id="/google/tags">
                                  <NavLink
                                    to="/marketing/facebook-ads"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#705EE6] hover:bg-[#EDECF9] !no-underline transition-all " +
                                      (isActive &&
                                        " !text-[#705EE6] !bg-[#EDECF9] !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Facebook Ads{" "}
                                      <span className="ml-2 bg-[#FFD6B2]  text-center py-[4px] px-2 !rounded text-[8px] text-[#3C3E49]  ">
                                        Coming Soon
                                      </span>
                                    </span>
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink
                                    to="/google/tags"
                                    className={({ isActive }) =>
                                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                      ((isActive ||
                                        window.location.pathname.includes(
                                          "google/tag"
                                        )) &&
                                        " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                    }>
                                    {isOpen && (
                                      <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                    )}
                                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                      Manage Google Tags {CurrentServer&&FavUnFavReturn('Google Tag Manager')}
                                    </span>
                                  </NavLink>
                                </li>

                                {false && (
                                  <>
                                    <li>
                                      <NavLink
                                        to="/coupons"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#4A545E]/10 !font-medium")
                                        }>
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Insights
                                        </span>
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/coupons-data"
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#4A545E] hover:bg-[#ffc800] !no-underline transition-all " +
                                          (isActive &&
                                            " !text-[#4A545E] !bg-[#ffc800]/10 !font-medium")
                                        }>
                                        {isOpen && (
                                          <HiOutlineSpeakerphone className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Coupons Data
                                        </span>
                                      </NavLink>
                                    </li>
                                  </>
                                )}

                                   <li
                      className={
                        (user?.booking_system_count &&
                          user?.booking_system_count) ||
                        0 >= ValidationNumber
                          ? ""
                          : "pointer-events-none"
                      }>
                      {" "}
                      <Disclosure as="div" defaultOpen={tabclass("affiliates")}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10   transition-all">
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                {/* <LuFolderSymlink className="text-primary shrink-0 text-lg" /> */}
                                <span className="sidebar_text">
                                  {!user.sidebar ? (
                                    "Affiliates"
                                  ) : (
                                    <>
                                      Comm
                                      <br />
                                      Channel
                                    </>
                                  )}
                                </span>
                              </span>

                              <TiArrowSortedDown
                                className={`${
                                  open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                            <Transition
                              enter="transition duration-300 ease-in-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-300 opacity-300"
                              leave="transition duration-300 ease-in-out"
                              leaveFrom="transform scale-300 opacity-300"
                              leaveTo="transform scale-95 opacity-0">
                              <Disclosure.Panel
                                className={`${
                                  !isOpen && "pl-[25px]"
                                } mt-[4px] `}>
                                <ul className="space-y-2">
                                  <li id="/affiliate">
                                    <NavLink
                                      to="/affiliate"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }>
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Affiliate DataBase {FavUnFavReturn('Affiliate Database')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/current-affiliate">
                                    <NavLink
                                      to="/current-affiliate"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Current Affiliate {FavUnFavReturn('Current Affiliate')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/request">
                                    <NavLink
                                      to="/request"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Affiliate Request {FavUnFavReturn('Affiliate Request')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id={BannerURL.general}>
                                    <NavLink
                                      to={BannerURL.general}
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        General Banners {FavUnFavReturn('General Banners')}
                                      </span>
                                    </NavLink>
                                  </li>

                                  <li id={BannerURL.product}>
                                    <NavLink
                                      to={BannerURL.product}
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Products Banners {FavUnFavReturn('Products Banners')}
                                      </span>
                                    </NavLink>
                                  </li>

                                  {urlAllow("default-commission") && (
                                    <li
                                      className={
                                        (user?.booking_system_count &&
                                          user?.booking_system_count) ||
                                        0 >= ValidationNumber
                                          ? ""
                                          : "pointer-events-none"
                                      }
                                      id={"/default-commission"}>
                                      <NavLink
                                        to={"/default-commission"}
                                        className={({ isActive }) =>
                                          "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                                          (location.pathname.includes(
                                            `/costing`
                                          ) ||
                                          location.pathname ==
                                            `/default-commission`
                                            ? " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium"
                                            : "")
                                        }>
                                        {isOpen && (
                                          <FiPackage className="text-inherit shrink-0 text-lg" />
                                        )}
                                        <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                          Default Commission {FavUnFavReturn('Default Commission')}
                                        </span>
                                      </NavLink>
                                    </li>
                                  )}

                                  <li id="/affiliateInbox">
                                    <NavLink
                                      to="/affiliateInbox"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ffc800] hover:bg-[#ffc800]/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-[#ffc800] !bg-[#ffc800]/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Inbox {FavUnFavReturn('Affiliate Requese')}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </li>
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}
           

              

                {CurrentServer && urlAllow("readOmniChannel") ? (
                  <>
                 

                    <li
                      className={
                        (user?.booking_system_count &&
                          user?.booking_system_count) ||
                        0 >= ValidationNumber
                          ? ""
                          : "pointer-events-none"
                      }>
                      {" "}
                      <Disclosure as="div" defaultOpen={tabclass("reports")}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              onClick={(e) => {
                                setCollapsedState(true);
                                dispatch(sidebar_base({ value: true }));
                              }}
                              className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-primary hover:bg-primary/10 transition-all">
                              <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                                <TbFileReport className="text-primary shrink-0 text-lg" />
                                <span className="sidebar_text">
                                  {!user.sidebar ? (
                                    "Reports"
                                  ) : (
                                    <>
                                      Comm
                                      <br />
                                      Channel
                                    </>
                                  )}
                                </span>
                              </span>

                              <TiArrowSortedDown
                                className={`${
                                  open ? "" : "-rotate-90 transform"
                                } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                              />
                            </Disclosure.Button>
                            <Transition
                              enter="transition duration-300 ease-in-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-300 opacity-300"
                              leave="transition duration-300 ease-in-out"
                              leaveFrom="transform scale-300 opacity-300"
                              leaveTo="transform scale-95 opacity-0">
                              <Disclosure.Panel
                                className={`${
                                  !isOpen && "pl-[25px]"
                                } mt-[4px] `}>
                                <ul className="space-y-2">
                                  <li id="/transactions">
                                    <NavLink
                                      to="/transactions"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Transactions {FavUnFavReturn('Transactions')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/report/sales">
                                    <NavLink
                                      to="/report/sales"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md flex items-center gap-[12px] text-sm font-normal text-[#4A545E] hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary !bg-[#705EE6]/10 !font-medium")
                                      }>
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        {" "}
                                        Sales {FavUnFavReturn('Sales')}
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/report/affiliates">
                                    <NavLink
                                      to="/report/affiliates"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary !bg-primary/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Affiliates {FavUnFavReturn('Affiliates')} 
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li id="/report/customers">
                                    <NavLink
                                      to="/report/customers"
                                      className={({ isActive }) =>
                                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-primary hover:bg-primary/10 !no-underline transition-all " +
                                        (isActive &&
                                          " !text-primary !bg-primary/10 !font-medium")
                                      }>
                                      {isOpen && (
                                        <PiHandbagBold className="text-inherit shrink-0 text-lg" />
                                      )}
                                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                                        Customers {FavUnFavReturn('Affiliate Customers')}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            )}
          </>
        ) : (
          <>
            <>
              <ul className="space-y-2">
                <li id="/company">
                  <NavLink
                    to={
                      user?.role?.name == "Affiliate"
                        ? "/affiliate/profile"
                        : "/company"
                    }
                    className={({ isActive }) =>
                      "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                      (isActive &&
                        " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                    }>
                    <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                      {user?.role?.name == "Affiliate"
                        ? "Profile"
                        : "Company Settings"}
                    </span>
                  </NavLink>
                </li>
                {CurrentServer && user?.role?.name == "Affiliate" && (
                  <li className="">
                    <Disclosure as="div" defaultOpen={tabclass("affiliates")}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            onClick={(e) => {
                              setCollapsedState(true);
                              dispatch(sidebar_base({ value: true }));
                            }}
                            className="w-full p-2.5 rounded-md flex items-center justify-between gap-[12px] text-[#4A545E] hover:!text-[#7b68ee] hover:bg-[#7b68ee]/10 transition-all">
                            <span className="text-sm font-normal text-inherit flex items-center gap-[12px]">
                              <IoChatboxEllipsesOutline className="text-[#7b68ee] shrink-0 text-lg" />
                              <span className="sidebar_text">
                                {!user.sidebar ? (
                                  "Affiliate Section"
                                ) : (
                                  <>
                                    Comm
                                    <br />
                                    Channel
                                  </>
                                )}
                              </span>
                            </span>
                            <TiArrowSortedDown
                              className={`${
                                open ? "" : "-rotate-90 transform"
                              } transition-all duration-500 h-4 w-4 text-[#7E8B99]`}
                            />
                          </Disclosure.Button>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel
                              className={`${!isOpen && "pl-[25px]"} mt-[4px] `}>
                              <ul className="space-y-2"></ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </li>
                )}

                {user?.companyName ? (
                  <li id="/activeplan">
                    <NavLink
                      to="/plans"
                      className={({ isActive }) =>
                        "p-2.5 rounded-md block text-sm font-normal text-[#4A545E] cursor-pointer hover:!text-[#ff9900] hover:bg-[#ff9900]/10 !no-underline transition-all " +
                        (isActive &&
                          " !text-[#ff9900] !bg-[#ff9900]/10 !font-medium")
                      }>
                      <span className="text-inherit sidebar_text leading-none flex items-center gap-2 justify-between w-full">
                        Plan
                      </span>
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </>
          </>
        )}
      </div>
    </>
  );
};

export default Html;
