// FloatingChatbot.js
import React, { useState } from 'react';
import { TbArrowsDiagonal } from 'react-icons/tb';
import { WiStars } from 'react-icons/wi';
import { BsThreeDots } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';

const FloatingChatbot = ({ isVisible , onClose }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <div>
      {isVisible && (
        <div
          className={`fixed bottom-1 right-0 p-3 ml-auto transition-all w-[calc(100%_-_280px)] ${isExpanded ? '' : 'w-full max-w-[800px]'}`}
        >
          <div className={`h-full relative transform rounded-large text-left transition-all w-full shadow-xl ${isExpanded ? 'w-full' : ''}`}>
            <div className={`h-full bg-white border-primary border rounded-large ${isExpanded ? '' : ''}`}>
              <div className="mt-3 text-center sm:mt-0 sm:text-left sm:w-full">
                <div className="flex justify-between gap-5 shadow-[0px_1px_2px_1px_#12376914] p-5 rounded-t-large">
                  <div className="flex gap-2 items-center">
                    <img src="/assets/img/logo-short.png" alt="logo" className="w-5 h-5" />
                    <h3 className="text-lg font-semibold text-typo">Dazhboards</h3>
                    <button className="hover:bg-primary hover:text-white transition-all items-center flex text-[#344054] font-medium text-xs w-10 h-[22px] py-0.5 px-1.5 border border-[#D0D5DD] rounded-md">
                      <WiStars className="text-[#8675F4] size-3 mr-[3px]" />
                      AI
                    </button>
                  </div>
                  <div className="flex gap-2">
                    <button type="button" className="p-1 hover:bg-primary group hover:text-white transition-all rounded-lg" onClick={handleExpand}>
                      <TbArrowsDiagonal className="size-5 text-[#7E8B99] hover:text-white" />
                    </button>
                    <button type="button" onClick={onClose} className="hover:text-red-500 transition-all rounded-lg">
                      <RxCross2 className="size-5 text-typo hover:text-red-500" />
                    </button>
                  </div>
                </div>
                <div className="flex justify-center items-center flex-col py-8">
                  <img src="/assets/img/logo-short.png" alt="logo" className="size-[56px] mb-4" />
                  <h2 className="text-lg font-semibold text-typo mb-1">Dazhboards AI Support</h2>
                  {isExpanded && (
                    <p className="text-sm font-normal text-[#404653]">Lorem ipsum dolor sit. Non tempor dictum.</p>
                  )}
                </div>
                <div className="px-5 max-h-[310px] h-full scrollbar overflow-auto">
                  <div className={`w-full ${isExpanded ? 'max-w-[1009px]' : 'max-w-[672px]'}`}>
                    <p className="bg-[#F5F6FA] py-2.5 px-3.5 mb-2 rounded-lg rounded-tl-none text-sm text-[#344054]">
                      Hi, Tell me what’s on your mind or pick a suggestion 🚀
                    </p>
                    <span className="block p-1 text-xs font-normal text-[#475467]">12:14pm</span>
                  </div>
                  <div className={`ml-auto w-full ${isExpanded ? 'max-w-[1009px]' : 'max-w-[672px]'}`}>
                    <p className="bg-[#F5F6FA] py-2.5 px-3.5 mb-2 rounded-lg rounded-tl-none text-sm text-[#344054]">
                      Lorem ipsum dolor sit amet consectetur. Urna habitasse velit nunc ornare urna.
                    </p>
                    <span className="block p-1 text-xs font-normal text-[#475467] text-right">12:14pm</span>
                  </div>
                </div>
                <div className="p-5 w-full flex gap-2 items-center">
                  <WiStars className="text-[#8675F4] size-4" />
                  <p className="text-sm italic font-normal text-[#8675F4]">AI generating</p>
                  <BsThreeDots className="text-[#8675F4]" />
                </div>
                <div className="p-5 border-t border-[#EAECF0] flex gap-3">
                  <input
                    type="text"
                    className="placeholder:font-light w-full h-11 shadow-box border !border-grey py-2.5 px-3.5 rounded-large text-sm leading-6 text-gray-900 focus:outline-primary"
                    placeholder="Send Message"
                  />
                  <button type="button" className="w-11 h-11 shrink-0 bg-primary hover:opacity-80 rounded-extra_large flex items-center justify-center">
                    <img src="/assets/img/svgs/send.svg" alt="" className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingChatbot;
