import React from "react";
import methodModel from "../../../methods/methods";
import Select from "react-select";
import { Listbox } from "@headlessui/react";
import { colourStyles } from "../../../utils/constants";
import { IoIosArrowDown } from "react-icons/io";

const Html = ({
  options,
  showPlaceHolder,
  selectedValues,
  handleChange,
  displayValue = 'name',
  id,
  placeholder,
  required,
  disabled,
  name,
  noDefault,
  className = '',
  theme = "normal",
  dynamicStyle
}) => {
  const categoryVal = () => {
    let ext = options && options.find((item) => item.id == selectedValues);
    return ext ? { value: ext.id, label: ext[displayValue] } : "";
  };
  const handleSelectChange = (objectData) => {
    handleChange(objectData?.id);
  };

  return (
    <>
      <div className={`selectDropdown ${className}  ${disabled ? 'disabledDropdown' : ''}`}>
        {theme == "search" ? (
          <>
            <Select
              styles={colourStyles}
              options={
                options?.map((itm) => {
                  return { value: itm.id, label: itm[displayValue] };
                }) || []
              }
              placeholder={placeholder}
              value={categoryVal()}
              isClearable={true}
              isDisabled={disabled}
              name={name}
              onChange={(e) => handleChange(e?.value || "")}
            // menuIsOpen={true}
            />
          </>
        ) : (
          <>
            <Listbox value={selectedValues} onChange={handleSelectChange} disabled={disabled}>
              <div className="list_box_active_state relative">
                <Listbox.Button
                  className={`relative focus:!outline-primary focus-visible:!outline-primary focus-visible:!outline-2 focus:!outline-2 focus-visible:!outline focus:!outline focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-black/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 disabled:!bg-gray-200 ${className ? className : "w-full"
                    } flex items-center justify-between gap-1.5 cursor-pointer rounded-extra_large bg-white !px-3 text-left  shadow-box border !border-grey h-10`}>
                  <span className="text-typo block text-sm font-normal truncate">
                    {selectedValues
                      ? methodModel.find(options, selectedValues, "id")?.[
                      displayValue
                      ] || placeholder
                      : placeholder}
                  </span>
                  <IoIosArrowDown
                    className="h-[18px] w-5 text-[#75757A]" />
                </Listbox.Button>
                <Listbox.Options
                  className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-primary focus:!outline text-sm absolute z-40 ${className ? className : " min-w-full"
                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white rounded-lg scrollbar`}>
                  {noDefault || !showPlaceHolder ? <></> : <>
                    <Listbox.Option
                      value={''}
                      className={({ active }) =>
                        `relative cursor-pointer capitalize select-none !py-3 px-3.5 ${active ? "!bg-primary text-white" : "text-gray-900"
                        }`
                      }>
                      <span
                        className={`block font-normal`}>
                        {placeholder}
                      </span>
                    </Listbox.Option>
                  </>}

                  {options &&
                    options.map((item, itemIdx) => (
                      <Listbox.Option
                        key={itemIdx}
                        disabled={item?.disabled ? true : false}
                        value={item}
                        className={({ active }) =>
                          `relative cursor-pointer capitalize select-none !py-3 px-3.5 ${(item?.isDisabled ? true : false) ? 'bg-grey' : ''} ${active ? "!bg-primary text-white" : "text-gray-900"
                          }`
                        }>
                        {({ selected }) => (
                          <>
                            <span
                              className={`block ${selected
                                ? "font-medium "
                                : "font-normal"
                                }`}>
                              {item[displayValue]}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </>
        )}
      </div>
    </>
  );
};

export default Html;
