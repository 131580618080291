import React, { useEffect, useState } from 'react'
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import SelectDropdown from '../../../components/common/SelectDropdown';
import { useSelector } from 'react-redux';

export  const WaiverSettings=()=>{
    const [ButtonSaved, setButtonSaved] = useState(false);
    const user = useSelector(state => state.user);
    const [Submitted, setSubmitted] = useState(false);
    const [ButtonDisabled, setButtonDisabled] = useState(true);
    const [form, setform] = useState({
        "send_reminders": false,
        "reminders_count": ''
    })
    useEffect(() => {
        loader(true)
        ApiClient.get(`api/settings/waiver`, {}, '', '', { apiCall: true }).then(res => {
            if (res.success) {
                setform(res.data)
            }
            loader(false)
        })
    }, [])
 


    const HandleFormSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
    
        loader(true)
        ApiClient.post('api/settings/waiver', { ...form }).then(res => {
            if (res.success) {
                setButtonSaved(true);
                setButtonDisabled(true)

                setTimeout(() => {
                    setButtonSaved(false)
                }, 2000);
            }
            loader(false)
        })
    }
    return(
        <div>
            <Layout>
       
<div className='p-2'> 
                    <div className="flex flex-col gap-1">
                        <h4 className="text-2xl font-semibold text-[#111827]">
                            Wavier Settings
                        </h4>
                        <p className="text-sm font-normal text-[#75757A]">
                            Waiver reminders are sent every 24 hrs until/before the travel date, based on the number of reminders, after an initial waiver is send for signing. 
                        </p>
                    </div>
                
                </div>


                <div className="max-w-[1020px]">
                    <div className="">
                        <div className="flex-3">
                            <form className="shadow-box border !border-grey  rounded-large container p-5 bg-white" onSubmit={HandleFormSubmit}>


                                <div className="flex items-center mb-4">
                                    <label className="text-sm font-normal text-[#344054] w-1/3">
                                        Do you want to Send Reminders?
                                    </label>
                                    <div className="flex  w-2/3 ">
                                        <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3  !rounded-large">
                                            <input
                                                className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                                                type="radio"
                                                value=""
                                                checked={form?.send_reminders}
                                                onChange={e => { setButtonDisabled(false); setform({ ...form, send_reminders: true }) }}
                                                name="showLogo2"
                                            />
                                            Yes
                                        </label>
                                        <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3 !rounded-large">
                                            <input
                                                className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                                                type="radio" 
                                                value="false"
                                                checked={!form?.send_reminders}
                                                onChange={e => { setButtonDisabled(false); setform({...form,send_reminders:false}) }}
                                                name="showLogo2"
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                {form?.send_reminders ?
                                    <>
                                  
                                        <div className="flex items-center mb-4">
                                            <h6 className="text-sm font-normal text-[#344054] w-1/3"> How many Reminders, Do you want to Send?</h6>
                                            <div className="pl-3 flex-1">
                                                <SelectDropdown
                                                    className='shadow-box   text-sm placeholder:text-gray-500 p-3   px-2 !ring-primary !outline-primary w-full max-w-80'
                                                    intialValue={form?.reminders_count}
                                                    result={e => { setButtonDisabled(false); setform({ ...form, reminders_count: e.value }) }}
                                                    options={[...new Array(5)].map((item,index)=>({id:index+1,name:index+1}))}
                                                    placeholder='Select Count' 
                                                />
                                            </div>
                                        </div>

                                    </>
                                    : null}
                                <div>
                                    <div className="flex justify-end">

                                         <button type="submit" disabled={ButtonDisabled} style={{ cursor: `${ButtonDisabled ? 'not-allowed !important' : ''}` }} className={`!px-4 text-sm text-right font-normal text-primary hover:text-white hover:!no-underline h-10 flex items-center justify-center gap-2 border border-primary rounded-extra_large shadow-btn hover:bg-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:!cursor-not-allowed`}  >
                                            {ButtonSaved ? 'Saved' : 'Save'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}