/*
 * @file: user.js
 * @description: Reducers and actions for store/manipulate Rule based Data
 * @date: 28.11.2019
 * @author: Anil
 */

const initialState = {value:true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'COLLAPSE':
            return { ...state, ...action.data };
        default:
            return state

    }
}
