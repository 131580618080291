let apiUrl = 'https://endpoint.dazhboards.com/'
let url = `https://${window.location.host}/`
const environment = {
  adminRoleId: '64b152a909d268f038611929',
  chatbot_api: "https://bot.dazhboards.com/",
  api: "https://endpoint.dazhboards.com/",
  review_api: 'https://appreviews.dazhboards.com/api/',
  bookingApi: 'https://endpoint.dazhboards.com/',
  url: 'https://app.dazhboards.com/',
  googleAPIKey: 'AIzaSyCWigOyC23DW0Ov9zUUQJi37hQ3lOW8LB0',
  // api:"http://192.168.1.23:6039/",

  userRoleId: '64b15102b14de6c28838f7d2',
  partnerRole: '64b15102b14de6c28838f7d2',
  SubRolePartner: '65d305b193650817f7620002',
  // fbAppId:'650078173094925',
  // fbConfig_id:null,
  fbAppId: '687148143612037',
  fbConfig_id: '1445414022852988',

  // map_api_key:'AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs',
  map_api_key: 'AIzaSyDXcaa81DeBgUyniq30GpZ5pYI7oDneKlE',
  resellerTypeId: '64b23b7d690d1d5f7ae76102',
  productTypeId: '64a7d198fa039f179c0320ca',
  OPENAI_API_KEY: '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'
};

console.log("host", url)
export default environment;
