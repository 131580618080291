import { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Header from "../header";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import methodModel from "../../../methods/methods";
import { FaArrowUp } from "react-icons/fa";
import environment from "../../../environment";
import swal from "sweetalert";
import socket from "../../../models/socket.model";
import { SidebarItems } from "../../../utils/constants";
import { useResizeListener } from "../../../hooks/useResizeListener";

const Layout = ({ children , classes = "" }) => {
  const WebDetails=useSelector(state=>state.logo);
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const history = useNavigate();
  const URLPath = window.location.pathname;
  const [showIcon, setshowIcon] = useState(false);

  

  useEffect(() => {
    if (!user?.loggedIn || !localStorage.getItem('token')) {
      history("/login");
    } else {
      if (window.location.pathname == '/') {
        if (!user.companyName) {
          history()
        } else {
          history('/dashboard')
        }
      }
    }
  }, [searchState, user]);

  const logo = () => {
    let value = WebDetails?.logo
    return value;
  };
  const logos = () => {
    let value = "/assets/img/logo-short.webp";
    return value;
  };
  const router = () => {
    let route = localStorage.getItem("route");
    history(route);
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  window.onscroll = function () {
    if (document.documentElement.scrollTop <= 50) {
      setshowIcon(false);
    } else {
      setshowIcon(true);
    }
  };




  const ParnterNavigateHandler = () => {
    if (user?.subRole?.id == environment.SubRolePartner) {
      if (user?.subscriptionId || user?.on_trial || user?.supplier_id) {

      } else {
        if (URLPath == '/review-dashboard' || URLPath == '/reviews' || URLPath == '/reseller-dashboard' || URLPath == '/reseller-data' || URLPath == '/crm' || URLPath == '/waiver' || URLPath == '/accounting/dashboard' || URLPath == '/accounting/transactions' || URLPath == '/accounting/invoices' || URLPath == '/omni-channels/live-chat' || URLPath == '/plans' || URLPath == '/activeplan'
          || URLPath == '/marketing/google-analytics' || URLPath == '/users' || URLPath == '/card' || URLPath == '/pos' || URLPath == '/coupons' || URLPath == '/coupons-data') {
          history(-1)
          swal({ text: "If you want to use this email as Supplier account. Ask your admin to make it disable or you can signup with new email as supplier", icon: "error" });

        }
      }
    }
  }
  useEffect(() => {
    ParnterNavigateHandler()
  }, [URLPath])



  useEffect(()=>{
    socket.on('new-fb-message',(data)=>{
      console.info(data,"THis is the Faceboook data info")
    })
  },[])


  const CurrentServer = window.location.href?.includes("app.dazhboards.com")
    ? false
    : true;
  const [PinnedStates,setPinnedStates]=useState(null)
  useEffect(()=>{
    if(user?.pinnedTabs&&CurrentServer){
    setPinnedStates([...user?.pinnedTabs])
    }else{
      setPinnedStates([])
    }
  },[user])

  useResizeListener(1440,setIsOpen,setIsOpen)
  return (
    <>
      <div component="layout">
        <div onClick={(e) => router()} id="routerDiv"></div>
        <Header />
        <div
          className={`main-wrapper flex `}>
          <div className={`main-sidebar ${user?.sidebar ? "active-sidebar" : ""
            } scrollbar transition-[width] duration-500`}>
            <div
              className="sidebar-brand p-3 pt-4 pl-5 text-left !bg-transparent"
              style={{ marginTop: "5px", height: "50px", marginBottom: "2px" }}>
              <label className="editLogo">
                <img src={logo()} className="logocls show-logo" width="250" height="35" />
                <img src={logos()} className="hide-logo" width="24" height="24" />
              </label>
            </div>
            {user?.logo ? (
              <div
                className="flex justify-center items-center"
              >
                <img
                  src={methodModel.userImg(user?.logo || "")}
                  alt="photo"
                  className="h-12 w-12 rounded-full object-contain"
                
                />
              </div>
            ) : null}
            <Sidebar />
          </div>
          <main className="main scrollbar">
            <div className="!bg-[#F8F9FB] !p-5 mt-[69px]">
{CurrentServer&&false && user?.pinnedTabs?.length > 0 && (
  <div className="flex flex-wrap gap-2 mb-8 bg-white rounded-xl p-4">
    {user?.pinnedTabs.map((item) => (
      <div style={{cursor:'pointer'}} className="flex flex-wrap justify-start gap-4" key={item} onClick={e => history(SidebarItems?.find(itemo => itemo?.name == item)?.Link||'')}>
        <div className="text-xs bg-gray-100 border border-gray px-2 py-1 rounded-full">
          {typeof item === 'string' ? item : ''}
        </div>
      </div>
    ))}
  </div>
)}

    
    {children}
    </div>
          </main>
        </div>
        {URLPath == "/sales" ||
          URLPath == "/dashboard" ||
          URLPath == "/review-dashboard" ||
          URLPath == "/product-dashboard" ||
          URLPath == "/customers" ||
          URLPath == "/marketing/google-analytics" ||
          URLPath == "/accounting/dashboard" ? (
          <div className={`z-[999] h-10 w-10 fixed bottom-16 right-16 cursor-pointer shadow-box border !border-grey bg-white flex items-center justify-center d-${showIcon ? "block" : "none"}`}>
            <FaArrowUp className={`d-${showIcon ? "block" : "none"}`}
              onClick={(e) => scrollToTop()}
              id="arrow-up"
              title="Back Top"
            />
          </div>
        ) : null}
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
export default Layout;
