import React from "react";
import Html from "./html";

const SelectDropdown = ({
  intialValue,
  options,
  result,
  showPlaceHolder = true,
  displayValue = "name",
  id,
  placeholder = "Select Status",
  disabled = false,
  name,
  required = false,
  noDefault = false,
  className = "",
  theme,
  dynamicStyle = false
}) => {
  const handleChange = (e) => {
    result({ event: "value", value: e });
  };

  return (
    <>
      <Html
        theme={theme}
        id={id}
        showPlaceHolder={showPlaceHolder}
        name={name}
        className={className}
        noDefault={noDefault}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        displayValue={displayValue}
        options={options}
        selectedValues={intialValue}
        handleChange={handleChange}
        dynamicStyle={dynamicStyle}
      />
    </>
  );
};

export default SelectDropdown;
