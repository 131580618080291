export const BannerAPIS={
    list:'api/banners',
    statusChange:'api/banner/status',
    delete:'api/banner',
    add:'api/banner',
    edit:'api/banner',
    view:'api/banner',
    export:'',
}
export const BannerURL={
    add:'/general/banners/add',
    edit:'/banner/edit',
    copy:'/banner/add',
    general:'/general/banners',
    product:'/products/banners'
}
export const BannerConfig={
    ListHeader:'Banners',
    AddHear:' Banner',
    permissionKey:'Banner'
}

export const BannerTypes=[{id:'general',name:'General'},{id:'product',name:'Product'}]

export const BannerNormalInputs=[
    { name: 'banner_name', required: true, placeHolder: 'Enter Name here', outerClass:'', label: 'Name', type: 'text', className: 'shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary' },
]