import React from "react";
import Layout from "../../components/global/layout";

const noPages = () => {
 

  return (
    <Layout>
      <div className="img_404page">
        <div className="">
           
                    <img src="/assets/img/404.jpg" className="erroe" />
        </div>
      </div>
    </Layout>
  );
};

export default noPages;
