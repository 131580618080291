export const CostingTourAPIS = {
  list: "api/template/product/expense/all",
  statusChange: "api/template/product/expense/status",
  delete: "api/template/product/expense",
  add: "api/template/product/expense",
  edit: "api/template/product/expense",
  view: "api/template/product/expense",
  export: "",
};
export const CostingTourURL = {
  add: "/costingtour/add",
  edit: "/costingtour/edit",
  copy: "/costingtour/add",
  list: "/costingtour/template",
};
export const CostingTourConfig = {
  ListHeader: "Costing Per Tour Templates",
  AddHear: " Costing Per Tour Template",
  permissionKey: "Products",
};

export const CostingTourNormalInputs = [
  {
    name: "title",
    required: true,
    placeHolder: "Enter Title here",
    outerClass: "col-span-2 mb-2 lg:col-span-12",
    label: "Title",
    type: "text",
    className:
      "shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary",
  },
];
