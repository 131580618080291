import React from 'react'

const ChatbotIcon = ({ classes = "" }) => {
  return (
    <svg width="18" className={`shrink-0 ${classes}`} height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.875 7.5H16.5V6.75C16.5 4.68225 14.8177 3 12.75 3H9.75V1.5C9.75 1.08525 9.414 0.75 9 0.75C8.586 0.75 8.25 1.08525 8.25 1.5V3H5.25C3.18225 3 1.5 4.68225 1.5 6.75V7.5H1.125C0.50475 7.5 0 8.00475 0 8.625V10.875C0 11.4952 0.50475 12 1.125 12H1.5V12.75C1.5 14.8177 3.18225 16.5 5.25 16.5H12.75C14.8177 16.5 16.5 14.8177 16.5 12.75V12H16.875C17.4953 12 18 11.4952 18 10.875V8.625C18 8.00475 17.4953 7.5 16.875 7.5ZM15 12.75C15 13.9905 13.9905 15 12.75 15H5.25C4.0095 15 3 13.9905 3 12.75V6.75C3 5.5095 4.0095 4.5 5.25 4.5H12.75C13.9905 4.5 15 5.5095 15 6.75V12.75ZM7.5 7.875C7.5 8.496 6.996 9 6.375 9C5.754 9 5.25 8.496 5.25 7.875C5.25 7.254 5.754 6.75 6.375 6.75C6.996 6.75 7.5 7.254 7.5 7.875ZM11.625 9C11.004 9 10.5 8.496 10.5 7.875C10.5 7.254 11.004 6.75 11.625 6.75C12.246 6.75 12.75 7.254 12.75 7.875C12.75 8.496 12.246 9 11.625 9ZM6.75 12C6.75 12.4148 6.414 12.75 6 12.75H5.25C4.836 12.75 4.5 12.4148 4.5 12C4.5 11.5852 4.836 11.25 5.25 11.25H6C6.414 11.25 6.75 11.5852 6.75 12ZM10.5 12C10.5 12.4148 10.1648 12.75 9.75 12.75H8.25C7.836 12.75 7.5 12.4148 7.5 12C7.5 11.5852 7.836 11.25 8.25 11.25H9.75C10.1648 11.25 10.5 11.5852 10.5 12ZM13.5 12C13.5 12.4148 13.1648 12.75 12.75 12.75H12C11.5852 12.75 11.25 12.4148 11.25 12C11.25 11.5852 11.5852 11.25 12 11.25H12.75C13.1648 11.25 13.5 11.5852 13.5 12Z" fill="currentColor" />
    </svg>
  )
}

export default ChatbotIcon